export function searchReload(id) {
  const home = document.querySelector("body.home");
  const elem = document.getElementById(id);
  if (!home && elem) {
    loadRadioButtons(elem);
  }
}

function loadRadioButtons(elem) {
  elem.addEventListener("change", (e) => {
    const item = e.target;
    if (item.matches('input[type=radio][name="produkte"], select')) {
      reloadHandler(e, item, elem);
    }
  });
}

function reloadHandler(e, item, elem) {
  return reloadSearchHTML(elem, item);
}

function setGetParameters() {
  return location.search.substr(1);
}

function setFormValues(elem) {
  const form = elem.querySelector("form");
  const formData = new FormData(form);
  let results = [];
  for (var pair of formData.entries()) {
    var string = pair[0] + "=" + pair[1];
    results.push(string);
  }
  const result = results.join("&");
  return result;
}

function reloadSearchHTML(elem, item) {
  fetch(frontend_calls.ajaxurl, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/x-www-form-urlencoded",
    }),
    body: new URLSearchParams({
      action: "refresh_search_form",
      produkte: item.value,
      postID: frontend_calls.postID,
      getParams: setGetParameters(),
      formParams: setFormValues(elem),
    }),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((response) => {
      console.log(response);
      if (response.html) {
        elem.classList.add(response.class);
        if ("sekt" !== response.class) {
          elem.classList.remove("sekt");
        }
        if ("prasente" !== response.class) {
          elem.classList.remove("prasente");
        }
        elem.innerHTML = response.html;
        item.removeEventListener("change", reloadHandler);
        loadRadioButtons(elem);
      }
    })
    .catch((err) => {
      console.log(err);
    });
}
