export function animateIn() {
  if ("IntersectionObserver" in window) {
    const select = [
      "h2",
      "h3",
      "h4",
      "p",
      "li",
      "img",
      "[class*=text]",
      ".accordion-item",
    ];

    function selectElement(el) {
      return el + ":not(.doNotAnimate)";
    }

    const selectors = select.map((el) => {
      const s = [
        ".page .content > * " + selectElement(el),
        "main > *:not(.page-wrap):not(.post-wrap) " + selectElement(el),
      ];
      return s.join(", ");
    });
    const newSelectors = selectors.join(", ");

    const entries = document.querySelectorAll(newSelectors);
    const elements = [].slice.call(entries);
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.25,
    };

    function callbackFunction(elements) {
      elements.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("make-visible");
          entry.target.classList.add("animate__slideInUp");
        }
      });
    }

    const observer = new IntersectionObserver(callbackFunction, options);
    elements.forEach((entry) => {
      entry.classList.add("make-invisible");
      entry.classList.add("animate__animated");
      observer.observe(entry);
    });
  } else {
    console.log("Your browser does not support IntersectionObserver");
  }
}
