export function lazyLoadBg() {
  if ("IntersectionObserver" in window) {
    const lazyBackgroundElements = document.querySelectorAll(".lazy-bg");

    function callbackFunction(entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const el = entry.target;
          let imageUrlWrap = getComputedStyle(el).getPropertyValue(
            "--series-bg-image-lazy"
          );
          imageUrlWrap = imageUrlWrap.replace(/\\/g, "");
          const matches = imageUrlWrap.match(/\bhttps?:\/\/\S+(?=\))/i);
          if (matches && matches.length > 0) {
            const loadImgSrc = matches[0];
            loadImage(loadImgSrc).then(() => {
              el.style.setProperty("--series-bg-image", imageUrlWrap);
            });
          }
        }
      });
    }

    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.25,
    };
    const observer = new IntersectionObserver(callbackFunction, options);

    lazyBackgroundElements.forEach((el) => {
      const imagePreview = getComputedStyle(el).getPropertyValue(
        "--series-bg-image-preview"
      );
      el.style.setProperty("--series-bg-image", imagePreview);
      observer.observe(el);
    });
  }
}

const loadImage = (src) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onerror = reject;
    img.src = src;
    img.onload = () => resolve(true);
  });
};
