import throttle from "lodash/throttle";

const nav = document.getElementById("navigation");
const header = nav;
let headerHeight = header.offsetHeight;

window.addEventListener("resize", () => {
  headerHeight = header.offsetHeight;
});

export function hideMenuUntilScroll() {
  const hide_menu = document.querySelector("body.hide_menu");
  if (!hide_menu) {
    window.addEventListener("scroll", stickHeader(header), {
      capture: true,
      passive: true,
    });
  } else {
    nav.classList.remove("position-relative");
    nav.classList.add("position-fixed");
    loadHideShowHeader(nav, headerHeight);
  }
}

const stickHeader = (header) =>
  throttle(() => {
    let scrollpos = window.scrollY;
    if (scrollpos > 0 && !header.classList.contains("position-fixed")) {
      header.classList.remove("position-relative");
      header.classList.add("position-fixed");
    } else if (
      scrollpos <= 0 &&
      !header.classList.contains("position-relative")
    ) {
      header.classList.remove("position-fixed");
      header.classList.add("position-relative");
    }
  }, 100);

const scrollHeader = (header) =>
  throttle(() => {
    hideShowHeader(header, headerHeight);
  }, 100);

function loadHideShowHeader(header, height) {
  header.style.marginTop = -height + "px";
  window.addEventListener("resize", scrollHeader(header), {
    capture: true,
    passive: true,
  });
  window.addEventListener("scroll", scrollHeader(header), {
    capture: true,
    passive: true,
  });
  window.onload = () => {
    hideShowHeader(header);
  };
}

function hideShowHeader(header, height) {
  let scrollpos = window.scrollY;
  if (scrollpos > 0) {
    header.style.boxShadow =
      "0 1px 5px rgba(0,0,0, 0.5), -1800px -10px 20px rgba(0,0,0,1), -1500px -20px 40px rgba(0,0,0,1), 1500px -20px 40px rgba(0,0,0,1), 1800px -10px 20px rgba(0,0,0,1)";
    header.style.top = height + "px";
  } else {
    var position = -(height + 20) + "px";
    header.style.boxShadow =
      "0 1px 5px rgba(0,0,0, 0), -1800px -10px 20px rgba(0,0,0,0), -1500px -20px 40px rgba(0,0,0,0), 1500px -20px 40px rgba(0,0,0,0), 1800px -10px 20px rgba(0,0,0,0)";
    header.style.top = position;
  }
}
