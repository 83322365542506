import FontFaceObserver from "fontfaceobserver";

export function loadFonts() {
  var PTSerif = new FontFaceObserver("PT Serif");
  var Avenir = new FontFaceObserver("Avenir");
  Avenir.load()
    .then(function () {
      sessionStorage.fontsLoaded = true;
      document.body.classList.add("fonts-loaded");
      document.body.style.setProperty("--font-sans", "Avenir");
      PTSerif.load()
        .then(function () {
          document.body.style.setProperty("--font-serif", "PT Serif");
        })
        .catch(function () {
          console.log("error loading PT Serif");
        });
    })
    .catch(function () {
      console.log("error loading Avenir");
      sessionStorage.fontsLoaded = false;
    });
}
