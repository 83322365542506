import Splide from "@splidejs/splide";
import "bootstrap/js/dist/dropdown";
import "bootstrap/js/dist/collapse";
import { hideMenuUntilScroll } from "./components/menu";
import { searchReload } from "./components/suche";
import { animateIn } from "./components/animateIn.js";
import { lazyLoadBg } from "./components/lazyBG.js";
import { loadFonts } from "./components/loadFonts.js";

document.addEventListener(
  "DOMContentLoaded",
  () => {
    loadFonts();
    window.Splide = Splide;
    lazyLoadBg();
    hideMenuUntilScroll();
    searchReload("search");
    animateIn();
  },
  { passive: true }
);

window.addEventListener(
  "load",
  () => {
    setTimeout(function () {
      document.getElementsByTagName("BODY")[0].classList.remove("preload");
    }, 70);
  },
  { passive: true }
);
